<template>
  <Layout>
    <section class="csms-charge-output">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Search Area Start -->
      <div class="row" v-if="$_app.user.roles[0] === 'ADMIN'">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label class="form-label" for="output-search-hq">{{ $t('common.headquarters') }}</label>
                      <multiselect id="output-search-hq"
                                   v-model="searchForm.hqId"
                                   label="text" track-by="value"
                                   :options="hqOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('output.noCb')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`output.${data.column}`) }}</span>
                  </template>

                  <template #cell(hqName)="data">
                    {{ data.item.chargePlace && data.item.chargePlace.hqBusiness ? data.item.chargePlace.hqBusiness.hqName : '-' }}
                  </template>
                  <template #cell(cpName)="data">
                    {{ data.item.chargePlace ? data.item.chargePlace.placeName : '-' }}
                  </template>
                  <template #cell(chargerType)="data">
                    {{ $_formatter.toLowerCaseString(data.item.chargerType) === 'fast' ? $t('common.fast') : $t('common.slow') }}
                  </template>
                  <template #cell(maxKw)="data">
                    <span>{{ data.item.maxKw }}</span>
                  </template>
                  <template #cell(connectorCount)="data">
                    {{ data.item.connectors.length }}
                  </template>
                  <template #cell(cbType)="data">
                    <template v-if="data.item.connectors && data.item.connectors.length > 0">
                      <div v-for="item in data.item.connectors" :key="item.id">
                        <div>
                          <span>{{ item.connectorType }}</span>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template #cell(connectorStatus)="data">
                    <template v-if="data.item.connectors && data.item.connectors.length > 0">
                      <div v-for="item in data.item.connectors" :key="item.id">
                        <div>
                          <span class="pe-2">{{ item.connectorLinkedId }}</span>
                          [<span :class="$_formatter.cbStatusTextColor($_app.code.chargePointStatus[item.status] ?? item.status)">{{ $_app.code.chargePointStatus[item.status] ?? item.status }}</span>]
                        </div>
                      </div>
                    </template>
                  </template>
                  <template #cell(configLimit)="data">
                    <span v-if="data.item.configLimit">{{ data.item.configLimit }}</span>
                    <span v-else>-</span>
                  </template>
                  <template #cell(recentLimit)="data">
                    <span v-if="data.item.recentLimit">{{ data.item.recentLimit }}</span>
                    <span v-else>-</span>
                  </template>
                  <template #cell(transactionStopTimestamp)="data">
                    {{ !$_utils.isEmpty(data.item.transactionStopTimestamp) ? data.item.transactionStopTimestamp : '-' }}
                  </template>
                  <template #cell(chargingProfileResponseStatus)="data">
                    {{ !$_utils.isEmpty(data.item.chargingProfileResponseStatus) ? data.item.chargingProfileResponseStatus : '-' }}
                  </template>
                  <template #cell(chargingProfileResponseTimestamp)="data">
                    {{ !$_utils.isEmpty(data.item.chargingProfileResponseTimestamp) ? data.item.chargingProfileResponseTimestamp : '-' }}
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

              <div class="row" v-if="$_app.user.roles[0] !== 'CP'">
                <div class="col-xl-6"></div>
                <div class="col-xl-6">
                  <div class="float-end">
                    <b-button variant="warning" @click="edit">
                      <i class="uil uil-cog me-2"></i>
                      <span>{{ $t('btn.output') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <edit-modal ref="outputEditModal" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import EditModal from '@/views/pages/charge-output/ChargeOutputEditModal.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, EditModal
  },
  data() {
    return {
      title: 'chargeOutput',
      hqOptions: [],
      searchConditions: [{ 'searchKey': 'chargerType', 'searchValue': 'FAST' }],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        hqId: { text: i18n.t('common.total'), value: null }
      },
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'cpName', label: '충전소명', class: 'text-center' },
        { key: 'chargeBoxName', label: '충전기명', class: 'text-center' },
        { key: 'chargeBoxId', label: '충전기 ID', class: 'text-center' },
        { key: 'chargerType', label: '충전 유형', class: 'text-center' },
        { key: 'maxKw', label: '충전 속도', class: 'text-center' },
        { key: 'connectorCount', label: '충전구 수', class: 'text-center' },
        { key: 'cbType', label: '충전기 유형', class: 'text-center' },
        { key: 'connectorStatus', label: '충전구 ID [상태]', class: 'text-center' },
        { key: 'configLimit', label: '설정 속도', class: 'text-center' },
        { key: 'recentLimit', label: '최근 충전 속도', class: 'text-center' },
        { key: 'transactionStopTimestamp', label: '최근 충전 일시', class: 'text-center' },
        { key: 'chargingProfileResponseStatus', label: '설정 응답 결과', class: 'text-center' },
        { key: 'chargingProfileResponseTimestamp', label: '설정 응답 일시', class: 'text-center' }
      ],
      items: null
    }
  },
  mounted() {
    const vm = this;

    vm.getHqOptions();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [{ text: i18n.t('common.total'), value: null }];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    reset() {
      const vm = this;

      vm.searchForm = {
        hqId: { text: i18n.t('common.total'), value: null }
      };
      vm.searchConditions = [{ 'searchKey': 'chargerType', 'searchValue': 'FAST' }];

      vm.getItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [{ 'searchKey': 'chargerType', 'searchValue': 'FAST' }];

      if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
        vm.searchConditions.push({ searchKey: 'hqName', searchValue: vm.searchForm.hqId.text });
      }

      vm.pagination.currentPage = 1;
      vm.getItems();

      /*if (this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [{ 'searchKey': 'chargerType', 'searchValue': 'FAST' }];

        if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
          vm.searchConditions.push({ searchKey: 'hqName', searchValue: vm.searchForm.hqId.text });
        }

        vm.pagination.currentPage = 1;
        vm.getItems();
      }*/
    },
    async getItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Charge Box List Error :: ', err)
      });

      vm.hideOverlay();
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        await vm.getItems();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        await vm.getItems();
      }
    },
    edit() {
      const vm = this;

      vm.$nextTick(() => {
        this.$refs.outputEditModal.show();
      });
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
