<template>
  <b-modal header-class="ion-bg-modal-header" size="md"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('output.outputTitle') }} {{ $t('common.setting') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="output-update-hq">{{ $t('common.headquarters') }}</label>
            <multiselect id="output-update-hq"
                         v-model="form.hqId"
                         label="text" track-by="value"
                         :options="hqOptions"
                         :placeholder="$t('alert.selectHq')"
                         selectLabel=""
                         selectedLabel=""
                         :deselectLabel="$t('common.unselect')"
                         :searchable="false" :allow-empty="true"
                         :disabled="$_app.user.roles[0] !== 'ADMIN'"
                         :class="{ 'is-invalid': submitted && $v.form.hqId.$error }" />
            <div v-if="submitted && $v.form.hqId.$error" class="invalid-feedback">
              <span v-if="!$v.form.hqId.required">{{ $t('validation.hqIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="ion-modal-form-group-title pt-3 pb-2">{{ $t('output.cbList') }}</div>
        <!-- // 90 kW -->
        <div class="row mb-3">
          <div class="col-xl-3 fw-bold">{{ $t('common.fast') }} 90 kW</div>
          <div class="col-xl-7">
            <range-slider class="slider w-100"
                          min="0" max="90" step="1"
                          v-model="form.val90" />
          </div>
          <div class="col-xl-2 fw-bold text-end">{{ form.val90 }} kW</div>
        </div>
        <!-- // 120 kW -->
        <div class="row mb-3">
          <div class="col-xl-3 fw-bold">{{ $t('common.fast') }} 120 kW</div>
          <div class="col-xl-7">
            <range-slider class="slider w-100"
                          min="0" max="60" step="1"
                          v-model="form.val120" />
          </div>
          <div class="col-xl-2 fw-bold text-end">{{ form.val120 }} kW</div>
        </div>
        <!-- // 180 kW -->
        <div class="row mb-3">
          <div class="col-xl-3 fw-bold">{{ $t('common.fast') }} 180 kW</div>
          <div class="col-xl-7">
            <range-slider class="slider w-100"
                          min="0" max="90" step="1"
                          v-model="form.val180" />
          </div>
          <div class="col-xl-2 fw-bold text-end">{{ form.val180 }} kW</div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import { required } from 'vuelidate/lib/validators';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'

export default {
  components: {
    Multiselect, RangeSlider
  },
  data() {
    return {
      visible: false,
      submitted: false,
      hqOptions: [],
      form: {
        hqId: null,
        val90: 0,
        val120: 0,
        val180: 0
      }
    }
  },
  validations: {
    form: {
      hqId: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getHqOptions();
      vm.visible = true;
    },
    getHqOptions() {
      const vm = this;

      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).then(() => {
        if (this.$_app.user.roles[0] === 'HQ') {
          vm.form.hqId = vm.hqOptions.find(ele => ele.value === this.$_app.user.hqId);
        }
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.hqOptions = [];

      vm.form = {
        hqId: null,
        val90: 0,
        val120: 0,
        val180: 0
      };

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmOutput'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePoint/setChargingProfileByHqId';

      let _form = {
        'hqId': vm.form.hqId.value,
        'connectorId': 0,
        'limit90': vm.form.val90,
        'limit120': vm.form.val120,
        'limit180': vm.form.val180,
        'unit': 'W'
      }

      http.post(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.changed'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Charge Box Create Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    },
  }
}
</script>

<style lang="scss">
::v-deep {
  .range-slider {
    width: 100%;
  }

  .range-slider-fill {
    background-color: #038EDCFF;
  }
}
</style>
